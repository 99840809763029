<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      statement: [],
    };
  },
  methods: {
    MainChart() {
      this.http.get("main-chart-of-accounts-types").then((res) => {
        this.mainChart = res.data;
      });
    },
    getStatement() {
      //     this.http.post("transactions/cashflow",this.data).then((res) => {
      //  this.cash =  res.data
      //     })
    },
  },
  created() {
    this.getStatement();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('reports.cahsflow_statement')"
    />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="">
              <ReportsHeader
                class="mb-2"
                :reportName="$t('reports.cahsflow_statement')"
              />
            </div>
            <div class="text-center">
              <p>{{ $t("reports.indirect") }}</p>
            </div>
            <div class="table-responsive">
              <table
                class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                  <tr>
                    <th>{{ $t("reports.foa") }}</th>
                  </tr>
                  <tr>
                    <th>
                      <div>{{ $t("reports.net_income") }}</div>
                    </th>
                    <td>1000</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{{ $t("reports.net_title") }}</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div>{{ $t("reports.depreication") }}</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div>{{ $t("reports.accounts_recivable") }}</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div>{{ $t("reports.accounts_payable") }}</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div>{{ $t("reports.loss") }}</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div>{{ $t("reports.net_cashflow") }}</div>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <th>{{ $t("reports.fia") }}</th>
                  </tr>
                  <tr></tr>
                  <tr>
                    <th>{{ $t("reports.ffa") }}</th>
                  </tr>
                  <tr></tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped></style>
